<template>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app :color="$const.color.primary" dark>
        <v-app-bar-nav-icon @click.prevent.stop="toggleSidebar"></v-app-bar-nav-icon>
        <v-toolbar-title class="ml-0 pl-4">
            <span class="hidden-sm-and-down">{{ title }}</span>
            <span class="hidden-sm-and-down caption ml-2 font-weight-bold">Активный год контента: {{ currentYear }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.prevent.stop="logout">
            <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
    </v-app-bar>
</template>
<script>
export default {
    name: 'HeadBar',
    data () {
        return {}
    },
    computed: {
        title () {
            return process.env.VUE_APP_PROJECT_NAME
        },
        currentYear () {
            return this.$store.state.app.currentYear || '-'
        }
    },
    methods: {
        toggleSidebar() {
            this.$emit('toggle-sidebar', 1);
        },
        async logout() {
            await this.$store.dispatch('user/logout');
            this.$router.go('/');
        }
    }
}
</script>