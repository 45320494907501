<template>
    <v-app id="inspire">
        <sidebar :visible.sync="drawer" />
        <head-bar @toggle-sidebar="drawer = !drawer" />
        <v-main>
            <v-container fluid>
            	<v-row align="start" justify="start" dark>
        			<v-col cols="12">
            			<v-breadcrumbs
					      :items="breadcrumbs"
					      class="px-0 py-2"
					    >
                            <template #item="{ item }">
                                <router-link
                                    :to="item.href"
                                    :class="{'grey--text pointer-events-none': item.disabled}"
                                    style="text-decoration: none;"
                                >{{ item.text }}</router-link>
                            </template>
                        </v-breadcrumbs>
					    <v-divider class="my-2"></v-divider>
            		</v-col>
            	</v-row>
                <router-view :key="$route.path" />
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
import Sidebar from '@/components/template/Sidebar/Index.vue'
import HeadBar from '@/components/template/HeadBar.vue'
export default {
    components: {
        Sidebar,
        HeadBar
    },
    data() {
        return {
            drawer: true
        }
    },
    computed: {
    	breadcrumbs() {
    		return _.map(_.get(this.$route, 'meta.breadcrumbs', []), (item) => {
                const id = _.get(this, '$route.params.id', 0);
                _.set(item, 'href', _.replace(item.href, ':id', id));
                return item;
            });
    	}
    }
};
</script>